// src/pages/About.js
import React from 'react';
import './Documentacion.css';
import { Link } from 'react-router-dom';
const Documentacion = () => {
  return (
    <div>
    <div className='Espacio-titulo'>      
    </div>
    
    <div className='containerdocu'>

      <div className='subcontainerdocu'>
        <h1>Información relevante</h1>
        <table className='tabla-docu'>
          <tr>
            <div className='divisordocu1'></div>
          </tr>
          <tr>
            <th><a href="/inforele/SALIDA Y PUESTA DEL SOL.pdf" target="_blank" rel="noreferrer">Salida y puesta del sol</a></th>
          </tr>
          <tr>
            <th><a href="/inforele/ABREVIATURAS.pdf" target="_blank" rel="noreferrer">Abreviaturas</a></th>
          </tr>
          <tr>
            <th><a href="/inforele/ENR1.10.pdf" target="_blank" rel="noreferrer">Planificación de los vuelos</a></th>
          </tr>
          <tr>
            <th><a href="/inforele/RESTRICCIÓN VUELOS ENTTO.pdf" target="_blank" rel="noreferrer">Restricción vuelos de entrenamiento</a></th>
          </tr>
          <tr>
            <th><a href="/inforele/INDICADORES DE LUGAR.pdf" target="_blank" rel="noreferrer">Indicadores de lugar</a></th>
          </tr>
          <tr>
            <th><a href="/inforele/FRASEOLOGÍA AERONÁUTICA.pdf" target="_blank" rel="noreferrer">Manual de fraseología</a></th>
          </tr>
          <tr>
            <th><a href="/inforele/ESPACIOS AEREOS.pdf" target="_blank" rel="noreferrer">Clasificación de los espacios aéreos</a></th>
          </tr>          
          <tr>
            <th><a href="/inforele/MANUAL SDD 2014.pdf" target="_blank" rel="noreferrer">Manual SDD</a></th>
          </tr>
          <tr>
            <th><a href="/inforele/MANUAL CMD.pdf" target="_blank" rel="noreferrer">Manual CMD</a></th>
          </tr>
          <tr>
            <th><a href="/inforele/MATS.pdf" target="_blank" rel="noreferrer">MATS</a></th>
          </tr>
          <tr>
            <th><a href="/inforele/FLUJOS BOG NW.pdf" target="_blank" rel="noreferrer">Flujos BOG NW</a></th>
          </tr>
          <tr>
            <th><a href="/inforele/AD NO CTLD 122,9 MHz..pdf" target="_blank" rel="noreferrer">Aeródromo no controlado freq 122,9 MHz</a></th>
          </tr>
          <tr>
            <th><a href="/inforele/AUT VLOS PISTAS PRIVADAS.pdf" target="_blank" rel="noreferrer">Autorización vuelos pistas privadas</a></th>
          </tr>
          <tr>
            <th><a href="/inforele/FALLA EMA.pdf" target="_blank" rel="noreferrer">Falla EMA</a></th>
          </tr>
          <tr>
            <th><a href="/inforele/ERUPCIÓN VOLCANICA.pdf" target="_blank" rel="noreferrer">Erupción volcanica</a></th>
          </tr>
          <tr>
            <th><a href="/inforele/FRASEOLOGÍA RNAV.pdf" target="_blank" rel="noreferrer">Fraseología RNAV</a></th>
          </tr>
          <tr>
            <th><a href="/inforele/PAE HORARIOS DECANSOS.pdf" target="_blank" rel="noreferrer">PAE horarios de descanso</a></th>
          </tr>
          <tr>
            <th><a href="/inforele/CI 001 - V4.pdf" target="_blank" rel="noreferrer">Circular técnica reglamentaria 001</a></th>
          </tr> 
          <tr>
            <th><a href="/inforele/capacidadats.pdf" target="_blank" rel="noreferrer">Capacidad de los sectores ATS</a></th>
          </tr>
          <tr>
            <th><a href="/inforele/POS DEGRADACION.pdf" target="_blank" rel="noreferrer">POS Degradacion ATS regional occidente </a></th>
          </tr>
          <tr>
            <th><a href="/inforele/regreso.pdf" target="_blank" rel="noreferrer">Regreso a funciones operativas CTA</a></th>
          </tr>
          <tr>
            <th><a href="/inforele/minimosad.pdf" target="_blank" rel="noreferrer">Mínimos de utilización de aeródromo</a></th>
          </tr>
          <tr>
            <th><a href="/inforele/RAC211.pdf" target="_blank" rel="noreferrer">RAC 211</a></th>
          </tr>
          <tr>
            <th><a href="/inforele/RAC91.pdf" target="_blank" rel="noreferrer">RAC91</a></th>
          </tr>
          <tr>
            <th><a href="/inforele/MANUAL GUIA IEEPT.pdf" target="_blank" rel="noreferrer">Manual guía IEEPT</a></th>
          </tr>
          <tr>
            <th><a href="/inforele/PROCESOS DE ENTRENAMIENTO GSTA OCCIDENTE 2022.pdf" target="_blank" rel="noreferrer">Procesos Entrenamiento regional occidente</a></th>
          </tr>
          <tr>
            <th><a href="/inforele/2 PAS 01 DISEÑO TURNOS COORDINACION ATS.pdf" target="_blank" rel="noreferrer">PAS01 Diseño turnos regional occidente</a></th>
          </tr>
          <tr>
            <th><a href="/inforele/CIRCULAR TROP.pdf" target="_blank" rel="noreferrer">Circular TROP</a></th>
          </tr>
          <tr>
            <th><a href="/inforele/MUNA-APP CLO.pdf" target="_blank" rel="noreferrer">MUNA APP CLO</a></th>
          </tr>
          <tr>
            <th><a href="/inforele/MUNA-TWR CLO.pdf" target="_blank" rel="noreferrer">MUNA TWR CLO</a></th>
          </tr>
          <tr>
            <th><a href="/inforele/POS 02 17 AERONAVES SALIENDO QUE PRESENTAN FALLAS TÉCNICAS.pdf" target="_blank" rel="noreferrer">Aeronaves saliendo que presentan fallas técnicas Valle</a></th>
          </tr>
          <tr>
            <th><a href="/inforele/PLAN DE EMERGENCIAS AEROPORTUARIAS CALI.xls" target="_blank" rel="noreferrer">Plan de emergencia Cali</a></th>
          </tr>
          <tr>
            <th><a href="/inforele/FALLA EQUIPOS CALI.pdf" target="_blank" rel="noreferrer">Falla equipos técnicos Cali</a></th>
          </tr>
          <tr>
            <th><a href="/inforele/POS 02 2019 UTILIZACION COMANDO AFTN SEND.pdf" target="_blank" rel="noreferrer">Uso comando AFTN SEND en Cali</a></th>
          </tr>
        </table>
      </div>

      <div className='subcontainerdocu'>
        <h1>Cartas de acuerdo</h1>
        <table className='tabla-docu'>
          <tr>
            <div className='divisordocu2'></div>
          </tr>
          <tr>
            <th><a href="/cartasacu/CARTA DE ACUERDO SEI Y TWR SKCL.pdf" target="_blank" rel="noreferrer">CLO TWR - SEI</a></th>
          </tr>
          <tr>
            <th><a href="/cartasacu/LOA CLO TWR - APP.pdf" target="_blank" rel="noreferrer">CLO TWR - APP CLO</a></th>
          </tr>
          <tr>
            <th><a href="/cartasacu/LOA CLO ACC - TWR ALBONAR.pdf" target="_blank" rel="noreferrer">CLO TWR - ACC CLO</a></th>
          </tr>
          <tr>
            <th><a href="/cartasacu/CARTADEACUERDOATSAIMMETIDEAM_V1.pdf" target="_blank" rel="noreferrer">ATS/AIS - IDEAM</a></th>
          </tr>          
          <tr>
            <th><a href="/cartasacu/ACC CLO - GPI TWR.pdf" target="_blank" rel="noreferrer">ACC CLO - GPI TWR</a></th>
          </tr>
          <tr>
            <th><a href="/cartasacu/ACC CLO - PSO TWR.pdf" target="_blank" rel="noreferrer">ACC CLO - PSO TWR</a></th>
          </tr>
          <tr>
            <th><a href="/cartasacu/ACC CLO - TCO TWR.pdf" target="_blank" rel="noreferrer">ACC CLO - TCO TWR</a></th>
          </tr>
          <tr>
            <th><a href="/cartasacu/ACCAPP CLO - BUN TWR.pdf" target="_blank" rel="noreferrer">ACC/APP CLO - BUN TWR</a></th>
          </tr>
          <tr>
            <th><a href="/cartasacu/ACCAPP CLO - PPN TWR.pdf" target="_blank" rel="noreferrer">ACC/APP CLO - PPN TWR</a></th>
          </tr>
          <tr>
            <th><a href="/cartasacu/ATS CLO - AIS CLO.pdf" target="_blank" rel="noreferrer">ATS CLO - AIS CLO</a></th>
          </tr>
          <tr>
            <th><a href="/cartasacu/GBT - PPN TWR.pdf" target="_blank" rel="noreferrer">GBT - PPN TWR</a></th>
          </tr>
          <tr>
            <th><a href="/cartasacu/ACC CLO - APP PEI.pdf" target="_blank" rel="noreferrer">Sala radar CLO - APP PEI</a></th>
          </tr>
          <tr>
            <th><a href="/cartasacu/LOA ACC BOG AREA Y CLO TMA.pdf" target="_blank" rel="noreferrer">BOG área y TMA/CTA CLO </a></th>
          </tr>
          <tr>
            <th><a href="/cartasacu/ACC CLO GBT CONTROL Y TWR - ULQ TWR.pdf" target="_blank" rel="noreferrer">ACC CLO/GBT y TWR CLO/ULQ/GBT</a></th>
          </tr>
          <tr>
            <th><a href="/cartasacu/CLO - GBT.pdf" target="_blank" rel="noreferrer">TWR CLO - TWR GBT</a></th>
          </tr>
          <tr>
            <th><a href="/cartasacu/POS GBT.pdf" target="_blank" rel="noreferrer">POS GBT</a></th>
          </tr>
          <tr>
            <th><a href="/cartasacu/CARTA DE ACUERDO ATC CECOA.pdf" target="_blank" rel="noreferrer">ATS CLO - CECOA</a></th>
          </tr>
          <tr>
            <th><a href="/cartasacu/AERONAVES REMOTAMENTE TRIPULADAS.pdf" target="_blank" rel="noreferrer">Operación de ART</a></th>
          </tr>
          <tr>
            <th><a href="/cartasacu/CODIGO AZUL.pdf" target="_blank" rel="noreferrer">Código azul</a></th>
          </tr>
        </table>
      </div>

      <div className='subcontainerdocu'>
        <h1>Otros útiles</h1>
        <table className='tabla-docu'>
          <tr>
            <div className='divisordocu3'></div>
          </tr>
          <tr>
            <th><a href="/cartasnav/MUDA.pdf" target="_blank">Cartas mudas</a></th>
          </tr>
          <tr>
            <th><a href="/inforele/LIQUIDACION HOREX.xlsx" target="_blank" rel="noreferrer">Calculo horex</a></th>
          </tr>
          <tr>
            <th><a href="/inforele/PERMISO REMUNERADO.pdf" target="_blank" rel="noreferrer">Permiso remunerado</a></th>
          </tr>
          <tr>
            <th><a href="/inforele/BOLETA TURNOS.pdf" target="_blank" rel="noreferrer">Boleta cambio de turno</a></th>
          </tr>
          <tr>
            <th><a href="/inforele/reportesVORMOR.pdf" target="_blank" rel="noreferrer">Qué son los reportes VOR y MOR ?</a></th>
          </tr>
          <tr>
            <th><a href="https://forms.office.com/Pages/ResponsePage.aspx?id=wFUnKXvwkUu7boczggnMlqi6Kz-0NnVHinX2nJrCaWpUMUdKSDNWUFIyUU9RT1lMS1BaVlYyWEtUUS4u" target="_blank" rel="noreferrer">Diligenciar VOR y MOR en línea</a></th>
          </tr>
          <tr>
            <th><a href="https://meteorologia.aerocivil.gov.co/obs_map" target="_blank" rel="noreferrer">METAR Colombia</a></th>
          </tr>
          <tr>
            <th><Link to="/tea">Preparación para el TEA</Link></th>
          </tr>
          <tr>
            <th><a href="https://www.daypo.com/ats-radar.html#test" target="_blank" rel="noreferrer">Test radar</a></th>
          </tr>
          <tr>
            <th><a href="/inforele/INSTRUCTIVO ISOLUCION.pdf" target="_blank" rel="noreferrer">Instructivo isolucion</a></th>
          </tr>
          <tr>
            <th><a href="https://isolucion.aerocivil.gov.co/Isolucion/PaginaLogin.aspx" target="_blank" rel="noreferrer">Link ingreso isolucion</a></th>
          </tr>
          <tr>
            <th><a href="https://controlt.aerocivil.gov.co/Control-T-war/faces/" target="_blank" rel="noreferrer">Control T</a></th>
          </tr>
          <tr>
            <th><a href="https://talentohumano.aerocivil.gov.co/webkactus/" target="_blank" rel="noreferrer">Personal Cajero</a></th>
          </tr>
        </table>
      </div>
    </div>
    </div>
  );
};


export default Documentacion;
